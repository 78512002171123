@font-face {
  src: url(../Fonts/IBMPlexSans-Light.ttf);
  font-family: IBMLight;
}

@font-face {
  src: url(../Fonts/IBMPlexSans-LightItalic.ttf);
  font-family: IBMLightItalic;
}

.pageStyle{
    padding: 1em;
    display: -webkit-flex;
    background: rgba(0, 0, 0, 0.3);
    flex-direction: column;
    white-space: normal;
    height: 100%;
}

.dateStyle2 {
  font-family: IBMLight;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  padding: 2px;
}

.pictureNote {
  color: #2ebbce;
  text-align: center;
  font-family: IBMLight;
}

.pictureWrapper1 {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 65%;
  padding-top: 15px;
}

.pictureWrapper2 {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 90%;
  padding-top: 15px;
}

.picturesWrapper3 {
  display: flex;
  flex-flow: row;
  margin: auto;
  width: 80%;
}

.profilePicture {
  border-radius: 50%;
  width: 100%;
}

.projectIcon {
  width: 100%;
}

.singlePicture {
  width: 100%;
  padding: 5px;
}

.horizontal {
  display: flex;
  flex-flow: row;
}

.horizontalDiv {
  padding: 5px;
  width: 100%;
  height: 100%;
}

.inLineLink {
  font-family: IBMLight;
  color: #2ebbce;
  font-size: 18px;
  text-decoration: none;
  transition: 250ms;
}

.inLineLink:hover {
  color: black;
  background: #2ebbce;
}

.pageHeader1{
  color: white;
  font-family: IBMLight;
}

.paragraph {
  padding: 2px;
  font-family: IBMLight;
  color: white;
  font-size: 18px;
}

.paragraph1 {
  padding-bottom: 20px;
  font-family: IBMLight;
  color: white;
  font-size: 18px;
  margin: 0px;
}

.imageWrap {
  width: 100%;
  height: auto;
  flex-shrink: 1;
  min-width: 0;
}

.imageWrap2 {
  width: 100%;
  height: auto;
  padding-left: 20px;
  flex-shrink: 1;
  min-width: 0;
}

.imageWrap4 {
  width: 100%;
  height: auto;
  padding-left: 20px;
  flex-shrink: 1;
  min-width: 0;
}

.imageWrap3 {
  width: 100%;
  height: auto;
  flex-shrink: 1;
  min-width: 0;
}

.image-container2 {
  width: 100%;
  height: 100%;
  padding-bottom: 20px;
}

.image-container {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  flex-wrap: wrap;
  padding-bottom: 20px;
}

.image-container img {
  flex: 1;
  width: 100%;
}

@media screen and (max-width: 460px) {
  .image-container {
    flex-direction: column;
    align-content: center;
  }

  .imageWrap {
    width: 100%;
    height: 100%;

    padding-bottom: 20px;
  }

  .imageWrap2 {
    padding-left: 0px;
  }

  .imageWrap4 {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 460px){

  .pageStyle{
    border-top-left-radius: 0px;
  }

  .horizontal {
    flex-flow: column;
  }

  .picturesWrapper3 {
    flex-flow: column;
  }
}
