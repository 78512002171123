@font-face {
  src: url(../Fonts/IBMPlexSans-Light.ttf);
  font-family: IBMLight;
}

@font-face {
  src: url(../Fonts/IBMPlexSans-Regular.ttf);
  font-family: IBMPlexSansMedium;
}

.header1 {
  font-family: IBMLight;
  color: #2ebbce;
}

.header3 {
  font-family: IBMLight;
  color: #2ebbce;
}

.headerLink {
  font-family: IBMLight;
  color: #2ebbce;
  text-decoration: none;
  transition: 250ms;
}

.headerLink:hover {
  color: black;
  background: #2ebbce;
}

.buttonStyle {
    display: flex;
    background: #1e727d;
    color: black;
    height: 100%;
    width: 80px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-family: IBMPlexSansMedium;
    transition: 300ms;
}

.buttonStyle-left {
  display: flex;
  background: #1e727d;
  color: black;
  height: 100%;
  width: 80px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-family: IBMLight;
  border-top-left-radius: 15px;
  transition: 300ms;
}

.buttonStyle-right {
  display: flex;
  background: #1e727d;
  color: black;
  height: 100%;
  width: 80px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-family: IBMLight;
  border-top-right-radius: 15px;
  transition: 300ms;
}

.navAboutActive {
  display: flex;
  background: #2ebbce;
  color: black;
  height: 100%;
  width: 80px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-family: IBMLight;
  border-top-left-radius: 15px;
  transition: 300ms;
}

.locatorStyle {
    background: none;
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.active{
  background: #2ebbce;
}

.navBar {
    display: -webkit-flex;
    flex-direction: row;
}

.webName {
    margin: initial;
    height: 100%;
    font-family: IBMLight;
    color: #2ebbce;
}

@media only screen and (max-width: 460px){

    .locatorStyle{
        flex-direction: column;
        text-align: left;
    }

    .buttonStyle,
    .buttonStyle-left,
    .buttonStyle-right{
        width: 100%;
    }


    .navBar{
        width: 100%;
        height: 43px;
    }

}
